$pink: #eb008b !default;
$orange: #ffc72b !default;
$red: #eb0045 !default;
$blue: #63c7a9 !default;
$darkblue: #031e3b !default;
$black: #343a3f !default;
$dark: #4d5256 !default;
$auth_background: #031e3b !default;
$loader_auth: #031e3b !default;
$gradient: linear-gradient(to right, #63c7a9, #031e3b, #eb0045) !default;
$primary_workinghours: #031e3b !default;
$error_notification: #ec2544 !default;
$warning_notification: #ffbb00 !default;
$confirmation_notification: #78d6a4 !default;
$info_notification: #65d0ec !default;
